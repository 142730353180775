@font-face {
	font-family: 'YS Text';
	src:
		url('//yastatic.net/islands/_/PyVcRbwHetz0gOVWLonWH7Od8zM.woff2') format('woff2'),
		url('//yastatic.net/islands/_/bIx8jOfCEfR-mECoDUEZywDBuHA.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'YS Text';
	src:
		url('//yastatic.net/islands/_/7_GKBdKFbUPzKlghJRv55xgz0FQ.woff2') format('woff2'),
		url('//yastatic.net/islands/_/SmqPmIMEXrW4lOY8QrhTUVDbrro.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'YS Text';
	src:
		url('//yastatic.net/islands/_/6Roy0LCd05cK4nNCipgzheYcNVU.woff2') format('woff2'),
		url('//yastatic.net/islands/_/KtHQR1erf3spayoIM4M4ngg0e2E.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

code {
	font-family: 'YS Text', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.secondary-text {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
}

.strong-text {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
}

.default-text {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}

body {
	margin: 0;
	font-family:
		'YS Text',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: rgba(0, 0, 0, 0.8);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff; /* 2 */
	font-feature-settings: 'pnum';
}

*,
*::before,
*::after {
	box-sizing: border-box; /* 1 */
}

html {
	line-height: 1.15; /* 2 */
}

/* 
	Отключаем фокусировку на элементах, к которым нельзя получить доступ с клавиатуры. 
	Это предотвращает появление ненужных контуров фокуса вокруг элементов, у которые все 
	еще есть pointer-events.

	Credit: https://github.com/suitcss/base
*/

[tabindex='-1']:focus {
	outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-block-start: 0;
	margin-block-end: 0.5em;
	color: rgba(0, 0, 0, 0.8);
	font-weight: 500;
}

p,
ol,
ul,
dl {
	margin-block-start: 0;
	margin-block-end: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-block-end: 0;
}

a {
	text-decoration: none;
}
